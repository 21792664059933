/* PrivacyPolicy.css */

/* General container styles, similar to your service-selection */
.content-container {
    margin: 0 15%;  /* Consistent horizontal margin with your booking page */
    font-family: Arial, sans-serif; /* Keeping the font consistent */
    padding: 20px 0; /* Vertical padding */
    border-radius: 5px; /* Rounded corners */
}

/* Heading styles similar to your h2 in BookingPage */
.privacy-policy h1, .privacy-policy h2 {
    color: black;
    font-size: 24px;
    font-weight: bold;
    margin: 20px; /* Add some margin around headings */
}

/* Paragraph styles */
.privacy-policy p {
    padding: 0 20px; /* Padding on the sides for better readability */
    line-height: 1.6; /* Increased line height for readability */
    color: #333; /* Slightly darker text for better contrast */
    margin-bottom: 20px; /* Space after paragraphs */
}

/* Link styling */
.privacy-policy a {
    color: #0066cc; /* Example link color, choose what fits your site */
    text-decoration: none; /* No underline */
}

.privacy-policy a:hover {
    text-decoration: underline; /* Underline on hover for clarity */
}

/* Consistent styling for your lists, if you have any */
.privacy-policy ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0;
    margin: 20px;
}

.privacy-policy li {
    background-color: #ffffff; /* White background for list items */
    margin-bottom: 10px; /* Space between list items */
    padding: 10px; /* Padding inside list items */
    border-radius: 5px; /* Rounded corners for list items */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow as in your service-selection */
}
