/* PrideSection.css */

.pride-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    padding: 5em 0; /* Use em units for scalability */
    width: 80%;
    margin: 0 10%; /* Center the section if it's narrower than the viewport */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.pride-text {
    text-align: center;
    padding: 0 1em; /* Padding to prevent text from touching the edges */
}

.pride-text h1, .pride-text h2, .pride-text h3 {
    margin: 0;
    padding: 0.5em 0; /* Slightly reduce the padding */
    font-family: 'Arial', sans-serif;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.pride-text h1 {
    font-size: 1.5em; /* Start with a smaller base size */
    color: #333;
}

.pride-text h2 {
    font-size: 1.25em;
    color: #333;
}

.pride-text h3 {
    font-size: 1em;
    color: #333;
}

/* Responsive font sizes */
@media (min-width: 768px) {
    .pride-text h1 {
        font-size: 2em; /* Larger font size for tablets and up */
    }
    
    .pride-text h2 {
        font-size: 1.75em;
    }
    
    .pride-text h3 {
        font-size: 1.25em;
    }
}

/* Even larger font sizes for larger screens */
@media (min-width: 992px) {
    .pride-text h1 {
        font-size: 2.5em;
    }
    
    .pride-text h2 {
        font-size: 2em;
    }
    
    .pride-text h3 {
        font-size: 1.5em;
    }
}

/* Add the heartbeat/breathing animation */
@keyframes heartbeat {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.pride-text:hover {
    animation: heartbeat 2.5s infinite;
}

/* Ensure the animation doesn't cause layout shifts */
.pride-text h1, .pride-text h2, .pride-text h3 {
    animation: fadeIn 2.5s ease forwards;
    backface-visibility: hidden; /* Improve performance on animations */
}

.pride-text:hover h1, .pride-text:hover h2, .pride-text:hover h3 {
    transform: scale(1.025); /* Slight increase on hover */
}
