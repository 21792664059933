/* CSS Reset for box-sizing */
*,
*:before,
*:after {
    box-sizing: border-box;
}

/* Base styles for services-section */
.services-section {
    text-align: center;
    padding: 30px 0px;
}

.service-card-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0; /* No padding by default */
    margin: 0 auto; /* Centers the section and applies automatic margin to both sides */
}

.service-card {
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    margin: 10px;
    flex: 0 1 calc(25% - 20px);
}

.service-card:hover {
    transform: translateY(-10px);
}

.service-card img {
    width: 100%;
    height: auto;
    display: block;
    padding: 7.5% 7.5% 2.5% 7.5%; /* Adjust the padding as needed */
    box-sizing: border-box; /* Ensures padding does not affect the width */
}

.service-card h3 {
    font-weight: bold;
    margin: 0;
    padding-bottom: 2.5%;
}

/* Media queries */
@media (max-width: 768px) {
    .service-card {
        flex: 0 1 calc(50% - 20px); /* 2 cards in a row */
    }
    .services-section {
        padding: 0 10%;
    }
}

@media (max-width: 480px) {
    .service-card {
        flex: 0 1 calc(100% - 20px); /* 1 card in a row */
    }
    .services-section {
        padding: 0 10%;
    }
}

@media (min-width: 768px) {
    .service-card-section {
        padding: 0 10%; /* 10% padding on larger screens */
    }

    .service-card {
        /* Adjust if needed to fit four cards with the new padding */
        flex: 0 1 calc(25% - 20px);
    }
}
