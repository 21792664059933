/* src/styles/Footer.css */

.site-footer {
  width: 100%;
  background-color: #333; /* Dark background for footer */
  color: white; /* Light text color for readability */
  padding: 20px 0; /* Padding above and below the content */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow above the footer */
}

.footer-content {
  display: flex;
  justify-content: space-between; /* Adjust as needed based on your layout */
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Padding on the sides */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.footer-links {
  list-style: none; /* Remove default list styling */
  padding: 0;
  margin: 0; /* Ensure no default margin */
  display: flex; /* Align list items in a row */
  flex-wrap: wrap; /* Allow items to wrap as needed */
  gap: 20px; /* Space between items */
}

.footer-links li {
  display: flex; /* Display links as flex to align items */
}

.footer-links a {
  color: white; /* Light link color */
  text-decoration: none; /* No underline */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.footer-links a:hover {
  text-decoration: underline; /* Underline on hover */
}

.footer-contact {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align items to the start */
}

.footer-contact p {
  margin: 0;
  line-height: 1.5; /* Spacing between lines */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column; /* Stack footer content vertically on small screens */
      align-items: center; /* Center items */
  }

  .footer-links, .footer-contact {
      align-items: center;
      text-align: center; /* Center text for links and contact info */
  }

  .footer-links {
      margin-bottom: 20px; /* Add space between links and contact info */
  }
}
