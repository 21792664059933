/* AboutPage.css */

.about-us-content {
    padding: 0 10%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  /* You may also want to style the individual sections within the container. */
  .about-us-content section {
    margin-bottom: 2rem; /* This adds spacing between sections */
  }
  
  /* For headings */
  .about-us-content h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #333; /* Change the color to fit your design */
  }
  
  /* For paragraphs */
  .about-us-content p {
    margin-bottom: 1rem;
    line-height: 1.6; /* This makes the text more readable */
  }
  
  /* For the button */
  .about-us-content .contact-us button {
    padding: 10px 20px;
    background-color: #5cb85c; /* Example button color - change as needed */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Adjust button styling on hover */
  .about-us-content .contact-us button:hover {
    background-color: #4cae4c; /* Darken color on hover */
  }
  
  /* Add more styling as needed to match your site's design */
  