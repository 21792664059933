/* Dropdown.css */

.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px; /* Ample padding for touch targets */
    font-size: 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    line-height: 1.15; /* Match the line-height of nav-item a elements */
    width: 100%; /* Full width for better mobile interaction */
    text-align: center; /* Align text to the center for consistency */
}

.dropdown {
    position: relative; /* Set a stacking context for the absolute positioning */
}

.dropdown-content {
    display: none; /* Hide the dropdown content by default */
    position: absolute; /* Absolutely position the dropdown content */
    background-color: #1c4219;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* Shadow for visual depth */
    z-index: 1000; /* High z-index to ensure it's on top of other content */
    width: calc(100% - 32px); /* Match the width of the dropdown, adjust for padding */
    top: 100%; /* Position directly below the dropdown button */
    left: 50%;
    transform: translateX(-50%); /* Center the dropdown */
}

.dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block; /* Block-level for full-width interaction area */
    border-top: 1px solid #ffffff; /* Separator between dropdown items */
}

.arrow-down {
    display: none;
}


@media (max-width: 768px) {
    .dropdown .dropdown-content {
        box-shadow: none; /* No shadow for a cleaner mobile look */
        width: 100%;
    }

    .dropdown:hover .dropdown-content {
        display: block; /* Allows for hover on devices with cursors */
    }
}

@media (min-width: 768px) {
    .dropdown:hover .dropdown-content {
        display: block; /* Show content on hover for non-touch devices */
        width: 100%; /* Full width of the button */
    }

    /* Additional styles for the dropdown button to align text */
    .dropbtn {
        justify-content: start; /* Align text to the start for desktop view */
        text-align: center; /* Align the text to the left for desktop view */
        padding: 1em 0;
        line-height: 1.15;
    }
}
