:root {
    --background-light: #f5f5f5;
    --border-color: #ddd;
    --background-white: #ffffff;
    --font-family-base: Arial, sans-serif;
    --box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    --input-padding: 10px;
    --border-radius: 5px;
    --button-font-size: 16px;
    --button-padding: 10px;
    --navigation-button-background: #e0e0e0;
}

.form-errors {
    color: red;
    margin-top: 10px;
  }
  
  /* Add or update styles for invalid input */
  input.invalid, select.invalid {
    border: 1px solid red;
  }
  
  /* Style error messages */
  .error-message {
    color: red;
    font-size: 0.8em;
  }

.booking-address {
    padding: 20px;
}

.booking-address label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.service-selection, 
.user-details-form {
    padding: 0 10%; 
    background-color: var(--background-light);
}

.service-selection {
    font-family: var(--font-family-base);
    background-color: var(--background-white);
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align children to the sides */
    align-items: center; /* Align children vertically */

}

.user-details-form select.form-control {
    width: 100%;
    padding: var(--input-padding);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: 16px;
    background-color: var(--background-white);
    -webkit-appearance: none; /* Removes default styling on iOS */
    -moz-appearance: none;    /* Removes default styling on Firefox */
    appearance: none;         /* Removes default styling */
  }
  

h2 {
    color: black;
    font-size: 24px;
    font-weight: bold;
}

.services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 35px;
    padding: 20px;
}

.services-list label {
    display: block;
    padding: 25px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.services-list label:hover {
    background-color: #f9f9f9;
}

.services-list input[type="radio"] {
    margin-right: 10px;
}

.user-details-form {
    font-family: var(--font-family-base);
    background-color: var(--background-white);
    padding: 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.form-group {
    margin: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.user-details-form input[type="text"],
.user-details-form input[type="email"],
.user-details-form textarea {
    width: 100%;
    padding: var(--input-padding);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: 16px;
    background-color: var(--background-white);
    margin-bottom: 10px;
}

.user-details-form textarea {
    resize: vertical;
    min-height: 100px;
}

.short-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* Navigation buttons container */
.next-button-div {
    display: flex;
    justify-content: space-between; /* This will put space between the two buttons */
    justify-content: flex-end;
    padding: 20px 0; /* Top and bottom padding */
    margin: 0px 20px;
    width: 100%;
}

/* Navigation buttons container */
.submit-button-div {
    display: flex;
    justify-content: space-between; /* This will put space between the two buttons */
    align-items: center; /* Align buttons vertically */
    padding: 20px 0; /* Top and bottom padding */
    gap: 30%;
    margin: 0px 20px;
    
}

/* Styles for the submit button, auto margins will center it when back button is not present */
.submit-button {
    padding: var(--button-padding);
    border-radius: var(--border-radius);
    cursor: pointer;
    background-color: var(--background-white);
    border: 1px solid var(--border-color);
    margin: 0 auto; /* Center the button when back button is not visible */
}

/* Styles for the back button */
.back-button {
    padding: var(--button-padding);
    border-radius: var(--border-radius);
    cursor: pointer;
    background: var(--navigation-button-background);
    border: none;
    flex: 1;
}

/* Styles for the forward button */
.forward-button {
    padding: var(--button-padding);
    border-radius: var(--border-radius);
    cursor: pointer;
    background: var(--navigation-button-background);
    border: 1px solid var(--border-color);
    font-size: var(--button-font-size);
    text-align: center; /* Center the text/icon within the button */

}

/* Invisible spacer to keep the submit button centered when back button is present */
.spacer {
    flex: 1;
    visibility: hidden;
    padding: var(--button-padding); /* Same padding as the back button */
}

@media (min-width: 768px) {
    .service-selection, 
    .user-details-form {
        margin: 0 10%;
    }
}

@media (max-width: 768px) {
    .service-selection, 
    .user-details-form {
        margin: 0 10%;
    }
    .short-details {
        grid-template-columns: 1fr; /* Stack inputs vertically on smaller screens */
    }

}

@media (max-width: 480px) {
    .service-selection, 
    .user-details-form {
        margin: 0 5%;
    }

}
