/* Base styles - Mobile First */
.header {
    background-color: #1c4219;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    position: relative;
}

.logo {
    max-height: 80px;
    z-index: 2;
}

.hamburger-menu {
    position: absolute;
    right: calc(25% - 21.6px); /* Adjust the 20px if the hamburger icon's width is different */
    top: 50%;
    transform: translateY(-50%);
    display: block;
    z-index: 3;
}

.nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #1c4219;
    z-index: 1;
}

.nav-links.active {
    display: flex;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.nav-item a, .nav-links .dropdown .dropdown-link, .booking-btn {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.15;
    padding: 1em 0; /* Adjust the value as needed */
    text-align: center;
    display: block;
}




.booking-btn {
    background-color: #4CAF50;
    border-radius: 5px;
    transition: background-color 0.3s;
    white-space: nowrap;
    padding: 10px 20px;
    margin: 1em 5em;
}

.nav-links.active > * {
    border-top: 1px solid #ffffff;
}


/* Larger screen styles */
@media (min-width: 768px) {
    .header {
        justify-content: space-between;
        padding: 10px 10%;
    }

    .logo {
        margin-right: auto;
    }

    .hamburger-menu {
        display: none;
    }



    .nav-links > *:not(:last-child) {
        padding: 0 0em; /* Adjust the value as needed */
      }
      

    .nav-links {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        background: none;
        gap: 2em;
    }

    .nav-links.active > * {
        border-top: none;
    }

    .header .nav-links .booking-btn {
        order: 1;
        padding: 1em 1em;
        margin: 0; /* If you want to remove the margin in desktop view */
    }

    .dropdown {
        position: relative;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #1c4219;
        min-width: 160px;
        box-shadow: 0 8px 16px rgba(0,0,0,0.2);
        z-index: 1000;
        width: 100%;
        top: 100%;
    }

    .dropdown-content a {
        border-top: 1px solid #ffffff; /* Separator between dropdown items */
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .arrow-down {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}
