/* CSS Reset for box-sizing */
*,
*:before,
*:after {
    box-sizing: border-box;
}

.marleys-section {
    padding: 30px 0px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    text-align: center; /* Centers the title and potentially other content */
}

.marleys-section h2 {
    margin-bottom: 30px;
}

.feature-grid {
    display: flex;
    justify-content: center; /* Center the items on the page */
    flex-wrap: wrap;
    gap: 15px;
    padding: 0 10%; /* Consistent with other sections for desktop view */
}

.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    margin: 10px; /* Consistent margin around cards */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    background: linear-gradient(135deg, #ffffff, #f2f2f2);
    transition: transform 0.2s, box-shadow 0.2s;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.emoji {
    font-size: 35px;
    margin-bottom: 15px;
}

.feature-card h3 {
    font-size: 14px;
    margin: 0;
}

/* Media query for tablets */
@media (max-width: 768px) {
    .feature-card {
        width: calc(50% - 30px); /* 2 cards in a row with the gap and padding */
    }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
    .feature-card {
        width: calc(100% - 30px); /* 1 card in a row with the gap and padding */
    }
}

@media (min-width: 768px) {
    .feature-grid {
        padding: 0 10%; /* Adds padding on larger screens */
    }
}
