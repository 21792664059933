.logo {
    max-width: 90%; /* Larger percentage for smaller screens */
}


/* Phone number and hours styling */
.phone, .hours {
    text-align: center;
    margin: 10px 0;
}


/* Services header styling */
.services-header {
    font-size: 1.5em;
    margin: 20px 0;
}

@media (max-width: 768px) {
    .services-header {
        font-size: 1.2em; /* Smaller font size for smaller screens */
    }
}


/* Assuming .service-item is a class for individual service blocks */
.service-item {
    width: 100%; /* Full width on smaller screens */
    box-sizing: border-box; /* Include padding and border in width */
    margin: 10px 0; /* Maintain vertical spacing */
}

@media (min-width: 768px) {
    .service-item {
        width: calc(50% - 20px); /* Two items per row on wider screens, adjust the 20px if you have a specific gutter width */
        margin: 10px; /* Add some horizontal margin */
    }
}

/* For the parent container of your service items, you might have a .services-list class */
.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* This will space out the items evenly */
    gap: 20px; /* You can use gap property if you want to maintain a consistent space between items */
}

@media (max-width: 768px) {
    .services-list {
        flex-direction: column; /* Stack items vertically on small screens */
    }
}
