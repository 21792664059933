.about-marleys,
.text-section,
.image-carousel {
    padding-left: 10%;
    padding-right: 10%;
}

.services-info {
    padding: 40px 0;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
}

.pricing-section .booking-btn{
    margin: 1em 5em;
}

.service-description {
    flex: 1;
    margin-bottom: 40px;
}

.service-description h2 {
    margin-top: 0;
}

.pricing-section {
    flex: 1;
    background-color: #e7e7e7;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.pricing-list {
    list-style-type: none;
    padding-left: 0;
}

.pricing-list li {
    line-height: 1.5;
    margin-bottom: 8px;
}

.special-offer {
    background-color: #1c4219;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
}

.image-carousel {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
}

.image-carousel h2 {
    margin-top: 0;
}

.carousel-item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Media query for smaller screens, like tablets */
@media (max-width: 768px) {
    .about-marleys,
    .text-section,
    .image-carousel {
        padding-left: 10%;
        padding-right: 10%;
    }

    .services-info {
        flex-direction: column;
        align-items: center;
    }

    .service-description,
    .pricing-section,
    .image-carousel {
        width: 100%; /* Full width on smaller screens */
    }
}

/* Media query for very small screens, like mobile phones */
@media (max-width: 480px) {
    .about-marleys,
    .text-section,
    .image-carousel {
        padding-left: 10%;
        padding-right: 10%;
    }


    .special-offer,
    .booking-btn {
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .booking-btn {
        font-size: 0.9em; /* Adjust font size for smaller screens */
    }
}

/* Ensure text doesn't overflow its container */
p, h1, h2, h3, h4, .special-offer, .pricing-list li {
    word-wrap: break-word;
    overflow-wrap: break-word;
}
