/* CSS Reset for box-sizing */
*,
*:before,
*:after {
    box-sizing: border-box;
}

/*Parent Contact Component*/
.contact-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0% 10%;
    background-color: #f9f9f9;
}

.contact-content-wrapper {
    display: flex;
    flex-direction: row; /* Set to column on smaller screens */
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

/* ContactPageTitle.css */
.contact-intro {
    width: 100%;
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px 0;
}

.contact-intro h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.contact-intro p {
    font-size: 1.2em;
}

/* ContactLeftComponent.css */
.contact-left-section {
    width: 100%; /* Set to 50% on larger screens */
    padding: 20px;
    background-color: #1c4219;
    color: white;
    font-family: Arial, sans-serif;
}

.contact-left-section h1,
.phoneaddress-details h2 {
    font-size: 2em;
    margin-bottom: 10px;
    border-bottom: 1px solid #333;
    padding-bottom: 10px;
}

.contact-left-section p,
.phoneaddress-details p {
    margin-bottom: 20px;
    line-height: 1.5;
    word-wrap: break-word; /* This will break the word if it's too long for its container */
}

.contact-details strong {
    color: #4CAF50;
}

.contact-details ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.contact-details li {
    margin: 8px 0;
    font-weight: bold;
}

/* ContactRightComponent.css */
.contact-right-section {
    width: 100%; /* Set to 50% on larger screens */
    padding: 20px;
    background-color: white;
    color: #333;
    font-family: Arial, sans-serif;
    border-left: none; /* Set border on larger screens */
}

.contact-right-section h1 {
    font-size: 2em;
    margin-bottom: 10px;
    border-bottom: 1px solid #333;
    padding-bottom: 10px;
}

.contact-right-section p {
    margin-bottom: 20px;
    line-height: 1.5;
}

.contact-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap; /* Allow icons to wrap on smaller screens */
}

.phone-icon,
.form-icon {
    flex-basis: 100%; /* Set to a percentage on larger screens */
    background-color: #e8e8e8;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Add space between the icons on wrap */
}

.icon-class-name {
    font-size: 24px;
    margin-bottom: 10px;
    color: #4CAF50;
}

/* Media queries */
@media (min-width: 768px) {
    .contact-left-section,
    .contact-right-section {
        width: 50%;
        padding: 20px 40px;
    }

    .contact-right-section {
        border-left: 1px solid #ddd;
    }

    .phone-icon,
    .form-icon {
        flex-basis: 45%; /* Adjust based on preference */
    }

    .phone-icon, .form-icon {
        cursor: pointer;
    }

    
}
